<template>
  <div class="detail-container">
    <div class="display-header">
      <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Display Resource") }} >{{ $t("Display Series") }}
      <div style="float:right;">
        <el-button v-if="itemsInfo['file_url']" type="primary" round @click="handleDownload" style="background-color: #218da0;" >{{ $t("Resource Download") }}</el-button>
      </div>
    </div>
    <div class="display-body">
      <div class="banner" v-if="banner && banner['image_url'] && banner['file_url']">
          <el-image class="img" :src="banner['image_url']" :alt="banner['title']" ></el-image>
          <video width="960" height="540" controls preload="auto"
                 :src="banner['file_url']" type="video/mp4"
                 :poster="banner['file_cover']">
          </video>
      </div>
      <div class="img-list" :style="'--size:' + size">
        <el-image class="img" v-for="item in imglist" :key="item['id']" :src="item['image_url']" :preview-src-list="[item['image_url']]" ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "display_detail",
  data(){
    return{
      itemsid:0,
      banner:{},
      imglist:[],
      size:0,
      itemsInfo:{}
    }
  },
  computed:{
  },
  created() {
    const query = this.$route.query;
    this.itemsid = +query.id
    if (this.itemsid===0){this.$router.push("/web/display")}
    this.handleGetAllDisplayDetail()
  },
  methods:{
    handleGetAllDisplayDetail(){
      this.$axios.get("/w1/displayitems/details",{params:{itemsid:this.itemsid}}).then((response) => {
        const {data} = response.data;
        this.banner = data.banner['items'][0];
        this.imglist = data.list['items'];
        this.size =this.imglist.length % 4;
        this.itemsInfo = data['items_info']
      })
    },
    handleDownload(){
      console.log(this.itemsInfo['file_url'])
      // window.location.href = this.itemsInfo['file_url']
    }
  }
};
</script>

<style scoped lang="scss">
.detail-container {

  padding: 20px;

  .display-header{
    font-size: 18px; width: 80%; margin: 0 auto; color: #515a6e
  }

  .display-body{

    padding-top: 20px;

    .banner{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      height: 550px;
        .img{
          width: 440px;
          height: 540px;
        }
    }

    .img-list{
      display: flex;
      flex-wrap: wrap;
      width: 1400px;
      justify-content: space-between;
      margin: 0 auto;
      &:after{
        content: "";
        width:calc(1400px - var(--size)*340px - var(--size) * 2 * 5px - 10px);
        display: block;
        height: 0;
      }
      .img{
        width: 340px;
        height: 340px;
        padding: 5px 0;
      }
    }
  }

}
</style>
